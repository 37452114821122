import { createFetchRequest, status, json } from './apiHelper';


/**
 *
 * Get SmartCloud Devices
 * @param {string} token jwtToken
 * @param {string} orgId org id
 * @param {string} regionId region id
 * @param {string} siteId site id
 *
 * @return {promise} Promise data
 */
export const getDevices = (token, orgId) => {
    let url = `/organizations/${orgId}/devices`;
    let request = createFetchRequest(url, token, 'GET');

    return fetch(request)
        .then(status)
        .then(json)
        .catch(function (error) {
            console.log('Request failed getDevices: ', error);
        });
};

/**
 *
 * Add New SmartCloud Device
 * @param {string} token jwtToken
 * @param {string} orgId org id
 * @param {string} deviceId deviceId id
 * @param {string} jsonData data JSON.stringfy
 *
 * @return {promise} Promise data
 */
export const editDevice = (token, orgId, deviceId, jsonData) => {
    let url = `/organizations/${orgId}/devices/${deviceId}`;
    let request = createFetchRequest(url, token, 'PUT', jsonData);

    return fetch(request).catch(function (error) {
        console.log('Request failed', error);
    });
};

/**
*
* Delete a SmartCloud Device
* @param {string} token jwtToken
* @param {string} orgId org id
* @param {string} regionId region id
* @param {string} siteId site id
* @param {string} devicesId site id
*
* @return {promise} Promise data
*/
export const deleteDevice = (token, orgId, regionId, siteId, devicesId) => {
    let url = `/orgs/${orgId}/regions/${regionId}/sites/${siteId}/devices/${devicesId}`;
    let request = createFetchRequest(url, token, 'DELETE');

    return fetch(request).catch(function (error) {
        console.log('Request failed', error);
    });
};


/**
*
* Delete a SmartCloud Device
* @param {string} token jwtToken
* @param {string} orgId org id
* @param {string} jsonSectorData site id
*
* @return {promise} Promise data
*/
export const addDevice = (token, orgId, jsonSectorData) => {
    let url = `/orgs/${orgId}/installNeptuneDevices`;
    let request = createFetchRequest(url, token, 'POST', jsonSectorData);

    return fetch(request).catch(function (error) {
        console.log('Request failed', error);
    });
};

/**
*
* ADD a SmartCloud Device to a group
* @param {string} token jwtToken
* @param {string} orgId org id
* @param {string} deviceType deviceType id
* @param {string} deviceId deviceId id
* @param {string} jsonGroups json containing groups array
*
* @return {promise} Promise data
*/
export const addDeviceToGroups = (token, orgId, deviceType, deviceId, jsonGroups) => {
    let url = `/orgs/${orgId}/deviceTypes/${deviceType}/deviceId/${deviceId}/addDeviceToGroups`;
    let request = createFetchRequest(url, token, 'PUT', jsonGroups);

    return fetch(request).catch(function (error) {
        console.log('Request failed', error);
    });
};

/**
*
* DELETE a SmartCloud Device to a group
* @param {string} token jwtToken
* @param {string} orgId org id
* @param {string} deviceType deviceType id
* @param {string} deviceId deviceId id
* @param {string} jsonGroups json containing groups array
*
* @return {promise} Promise data
*/
export const deleteDeviceFromGroups = (token, orgId, deviceType, deviceId, jsonGroups) => {
    let url = `/orgs/${orgId}/deviceTypes/${deviceType}/deviceId/${deviceId}/removeDeviceFromGroups`;
    let request = createFetchRequest(url, token, 'DELETE', jsonGroups);

    return fetch(request).catch(function (error) {
        console.log('Request failed', error);
    });
};
