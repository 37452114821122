export default function register() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            //service worker wont work if PUBLIC_URL is on a different origin
            return;
        }

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            navigator.serviceWorker.register(swUrl).then((registration) => {
                setInterval(() => {
                    registration.update();
                }, 1000 * 60); //check for updates once per minute
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker == null) {
                        return;
                    }

                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === 'installed') {
                            if (navigator.serviceWorker.controller) {
                                //the updated precached content has been fetched, clear the cache and reload the page
                                clearCaches().then(() => {
                                    registration.unregister();
                                    window.location.reload(true);
                                });

                            } else {
                                //precaching has finished
                                console.log('Content is cached for offline use.');
                            }
                        }
                    };
                };
            }).catch((error) => {
                console.error('Error during service worker registration:', error);
            });

        });
    }
}

async function clearCaches() {
    const allCaches = await caches.keys();
    const cacheDeletionPromises = allCaches.map(cache => caches.delete(cache));

    await Promise.all([...cacheDeletionPromises]);
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}
