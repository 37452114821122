import React from 'react';
import { baseURL, API_KEY } from '../config/settings';
import AppConfig from '../config/AppConfig';

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import FlipIcon from 'material-ui-icons/Flip';
import RotateLeftIcon from 'material-ui-icons/RotateLeft';
import RotateRightIcon from 'material-ui-icons/RotateRight';
import Paper from 'material-ui/Paper';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Snackbar from 'material-ui/Snackbar';
import Drawer from 'material-ui/Drawer';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import Chip from 'material-ui/Chip';
import { v4 as uuid } from 'uuid';

import MaterialSpinner from '../loading/material-spinner';

const DEVICE_TYPE_SYS2_MART_SPOT = 'sys2-smartspot';
const DEVICE_TYPE_SENSOR = 'sys2';
const DEVICE_TYPE_COUNTER = 'counter';
const DEVICE_TYPE_PARKEON_PAYMENT_MACHINE = 'parkeonPaymentMachine';
const DEVICE_TYPE_TICKET_VENDING_MACHINE = 'ticketVendingMachine';
const DEVICE_TYPE_MAV_CAMERA = 'mav-camera';

const CAMERACONFIG_POINTINGOUTSIDE = 'outside'; // camera is pointing out of the carpark
const CAMERACONFIG_POINTINGINSIDE = 'inside';  // camera is pointing into the carpark

class PlaceDevices extends React.Component {

    constructor(props) {
        super(props);
        this.divID = '__editMapHolder__';

        this.state = {
            showDrawer: false,
            snakbaropen: false,
            snak: '',
            multiDevicePopup: false,
            multiDevicePopupMulSelect: false,
            dialog_del_sngdevice_open: false,
            dialog_muldevice_open: false,
            disableSensorRef: false,
            singleDevicePopup: false,
            selectedMultiDeviceItem: null,
            groups: [],
        };
        const self = this;

        window.MapDevicesJSCommands = function () {
            self.MapDevicesJSCommands = this;
        };
        window.MapDevicesJSCommands.prototype.onReceive = function (callback) {
            this.onReceive = callback;
        };
        window.MapDevicesJSCommands.prototype.update = function (command, obj) {
            this.onReceive(command, obj);
        };

        window.MapDevicesJSCommands.prototype.command = function (command, object) {
            if (command.includes('open_drawer_select_multiple')) {
                if (command === 'open_drawer_select_multiple_rectangle') {
                    self.setState({ selectType: 'rectangle' });
                }
                if (command === 'open_drawer_select_multiple_polygon') {
                    self.setState({ selectType: 'polygon' });
                }
                object.moveSelected = true;
                self.setState({
                    showDrawer: true,
                    selectedMultiDeviceItem: object,
                    multiDevicePopup: false,
                    singleDevicePopup: false,
                    multiDevicePopupMulSelect: true,
                    groups: object.groups
                });
                self.MapDevicesJSCommands.update('moveSelecedDevices', self.state.selectedMultiDeviceItem);
            }
            if (command === 'open_mul_drawer') {
                object.moveSelected = true;
                self.setState({
                    showDrawer: true,
                    selectedMultiDeviceItem: object,
                    multiDevicePopup: true,
                    singleDevicePopup: false,
                    multiDevicePopupMulSelect: false,
                    multiDevicePopupShowSave: false,
                });
                self.MapDevicesJSCommands.update('moveSelecedDevices', self.state.selectedMultiDeviceItem);
                self.updateNumberOfDevices();
            }

            if (command === 'open_sng_drawer') {
                object.move = true;
                self.setState({
                    showDrawer: true,
                    selectedSngDeviceItem: object,
                    multiDevicePopup: false,
                    singleDevicePopup: true,
                    multiDevicePopupMulSelect: false,
                    disableSensorRef: object.sensor !== null && object.sensor !== ''
                });
                self.MapDevicesJSCommands.update('update_sng_device', self.state.selectedSngDeviceItem);
            }
            if (command === 'close_drawer') {
                self.closeDrawer();
            }

            if (command === 'show_snak_bar') {
                self.showSnakBar(object);
            }

        };
    }

    componentDidMount() {
        const { USER } = this.props;
        const org = USER.selectedOrg;
        const reg = USER.selectedRegion;
        const site = USER.selectedSite;
        this.selectedSite = site;

        if (org && reg && site) {
            this.loadMappingTool(USER, this.props);
        }
    }

    loadMappingTool = (USER, props) => {
        const { token } = props;
        const org = USER.selectedOrg;
        const reg = USER.selectedRegion;
        const site = USER.selectedSite;

        this.selectedSite = site;

        let divID = this.divID;
        let location = AppConfig.getSiteLocation(site);

        AppConfig.loadScript('/js/mapping/devices/plandevices.js', () => {
            if (window.MapAndCreateDevice) {
                this.MapAndCreateDevice = new window.MapAndCreateDevice(divID, token, org, reg, site, baseURL, API_KEY, uuid);
                this.MapAndCreateDevice.setLocation(location);
                this.MapAndCreateDevice.init();

            }
        });
    }


    componentWillReceiveProps(nextProps) {

        const org = nextProps.USER.selectedOrg;
        const reg = nextProps.USER.selectedRegion;
        const site = nextProps.USER.selectedSite;

        if (!org || !reg || !site) {
            return;
        }

        if (this.selectedSite !== site) {
            this.loadMappingTool(nextProps.USER, nextProps);
        }

        this.selectedSite = nextProps.USER.selectedSite;

    }


    showSnakBar = (message) => {
        this.setState({ snakbaropen: true, snak: message });
    }


    handleSnackBarRequestClose = () => {
        this.setState({
            snakbaropen: false,
        });
    };

    updateNumberOfDevices = () => {
        let to = this.parseToNumber(this.state.selectedMultiDeviceItem.to);
        let from = this.parseToNumber(this.state.selectedMultiDeviceItem.from);
        let oddEven = this.state.selectedMultiDeviceItem.oddEven;

        let f = from, t = to;
        let count = 0;
        for (f; f <= t; f++) {
            if (oddEven === 'All') {
                count++;
            } else if (oddEven === 'Odd') {
                count += f % 2 === 1 ? 1 : 0;
            } else if (oddEven === 'Even') {
                count += f % 2 !== 1 ? 1 : 0;
            }
        }
        let numberOfDevices = 'Number of devices will be drawn: ' + (count);
        if (from < 0 | to < 0) {
            numberOfDevices = '';
        }
        this.setState({ numberOfDevices });
    }

    changeSelectedMultiDeviceItem = (evt, id, command = 'update_mul_devices') => {
        let selected = this.state.selectedMultiDeviceItem;
        if (selected && evt.target) {
            selected[id] = evt.target.value;
            this.setState({
                selectedMultiDeviceItem: selected
            });
            this.updateNumberOfDevices();
            this.MapDevicesJSCommands.update(command, this.state.selectedMultiDeviceItem);
        }
    };

    startRotation = (event, direction) => {
        if (event.nativeEvent.which !== 1) {
            return;
        }
        const self = this;
        self.setState({ selectType: 'polygon' });
        self.MapDevicesJSCommands.update('rotateSelecedDevices', direction);
        this.interval = window.setInterval(function () {
            self.MapDevicesJSCommands.update('rotateSelecedDevices', direction);
        }, 25);
    }

    endRotation = () => {
        window.clearInterval(this.interval);
    }

    flipDevices = () => {
        this.MapDevicesJSCommands.update('flipSelectedDevices');
    }

    closeDrawer = () => {
        this.setState({
            showDrawer: false,
            multiDevicePopup: false,
            singleDevicePopup: false
        });

    }
    createMulDeviceClicked = () => {

        let to = this.parseToNumber(this.state.selectedMultiDeviceItem.to);
        let from = this.parseToNumber(this.state.selectedMultiDeviceItem.from);

        if (from < 0) {
            this.setState({ errorMulFrom: 'Must be positive' });
            return;
        }
        this.setState({ errorMulFrom: '', errorMulTo: '' });
        if (to < 0) {
            this.setState({ errorMulTo: 'Must be positive' });
            return;
        }
        this.setState({ errorMulFrom: '', errorMulTo: '' });

        this.MapDevicesJSCommands.update('draw_devices', this.state.selectedMultiDeviceItem);
        from = this.state.selectedMultiDeviceItem.from;
        to = this.state.selectedMultiDeviceItem.to;

        if (this.state.multiDevicePopupShowSave === false && (from !== 0 || to !== 0)) {
            this.setState({
                multiDevicePopupShowSave: (from <= to)
            });
        }

    }

    moveSelectedSaveClick = () => {
        this.MapDevicesJSCommands.update('save_moved_devices');
    }

    cancelMulClicked = () => {
        this.closeDrawer();
        this.MapDevicesJSCommands.update('remove_line');
        this.MapDevicesJSCommands.update('remove_selection');
    }

    saveMulDevices = () => {
        let to = this.parseToNumber(this.state.selectedMultiDeviceItem.to);
        let from = this.parseToNumber(this.state.selectedMultiDeviceItem.from);

        if (to < 0) {
            this.setState({ errorMulTo: 'Must be positive' });
            return;
        }
        if (from < 0) {
            this.setState({ errorMulFrom: 'Must be positive' });

            return;
        }
        this.setState({ errorMulFrom: '', errorMulTo: '' });

        this.closeDrawer();
        this.MapDevicesJSCommands.update('create_mul_device_in_cloud');
    }

    parseToNumber = (n) => {
        var h = Number(n);
        if (isNaN(h) || (typeof n === 'string' && n.trim() === '')) {
            return -1;
        }
        return h;
    }


    changeSelectedSngDeviceItem = (evt, id) => {
        let selected = this.state.selectedSngDeviceItem;
        if (selected && evt.target) {
            if (id === 'cameraConfig') {
                selected[id] = { ...selected[id], ...evt.target.value };
            } else {
                selected[id] = evt.target.value;
            }
            this.setState({
                selectedSngDeviceItem: selected
            });
            this.MapDevicesJSCommands.update('update_sng_device', this.state.selectedSngDeviceItem);
        }
    }

    deviceNameWithShadowed = () => {
        return this.state.selectedSngDeviceItem.name.toLowerCase().includes('shadow');
    }

    deleteSingleDeviceClicked = () => {
        this.setState({ dialog_del_sngdevice_open: true });
    }

    createSingleDeviceClicked = () => {
        if (this.state.selectedSngDeviceItem.type === DEVICE_TYPE_SYS2_MART_SPOT && this.state.selectedSngDeviceItem.sensor.trim() === '') {
            this.setState({ errorSngsensor: 'SmartSpot Reference Required' });
            return;
        }

        if (this.state.selectedSngDeviceItem.type === DEVICE_TYPE_COUNTER) {
            if (this.state.selectedSngDeviceItem.maximum && this.state.selectedSngDeviceItem.maximum.trim() === '') {
                this.setState({ errorSngCounter: 'Counter Must Have A Maximum Value' });
                return;
            }
            let val = this.parseToNumber(this.state.selectedSngDeviceItem.maximum);
            if (val < 0) {
                this.setState({ errorSngCounter: 'Maximum Vacancy Must Be A Positive Integer' });
                return;
            }
            if (!this.state.selectedSngDeviceItem.counterType) {
                this.setState({ errorSngCounter: 'Must have a counter type' });
                return;
            }
        }

        if (this.state.selectedSngDeviceItem.type === DEVICE_TYPE_PARKEON_PAYMENT_MACHINE || this.state.selectedSngDeviceItem.type === DEVICE_TYPE_TICKET_VENDING_MACHINE) {
            if (!this.state.selectedSngDeviceItem.serialNumber) {
                this.setState({ errorSerialNumber: 'Payment Machine Must Have A Serial Number' });
                return;
            }
            if (this.state.selectedSngDeviceItem.serialNumber.trim() === '') {
                this.setState({ errorSerialNumber: 'Payment Machine Must Have A Serial Number' });
                return;
            }
        }

        if (this.state.selectedSngDeviceItem.type === DEVICE_TYPE_MAV_CAMERA || this.state.selectedSngDeviceItem.type === DEVICE_TYPE_TICKET_VENDING_MACHINE) {
            if (!this.state.selectedSngDeviceItem.serialNumber) {
                this.setState({ errorSerialNumber: 'Camera Must Have A MAC Address' });
                return;
            }
            if (this.state.selectedSngDeviceItem.serialNumber.trim() === '') {
                this.setState({ errorSerialNumber: 'Camera Must Have A MAC Address' });
                return;
            }
        }

        if (!this.state.selectedSngDeviceItem.location) {
            this.setState({ errorLocation: 'Location Not Found' });
            return;
        }

        this.closeDrawer();
        this.setState({ errorSngsensor: '' });

        this.MapDevicesJSCommands.update('save_sng_device', this.state.selectedSngDeviceItem);

    }

    deleteMultyDeviceClicked = () => {
        this.setState({ dialog_muldevice_open: true });
    }



    handleMulDialogClose = (confim) => {
        this.setState({ dialog_muldevice_open: false });
        if (confim) {
            this.MapDevicesJSCommands.update('delete_mul_devices', this.state.selectedMultiDeviceItem.devices);
            this.cancelMulClicked();
        }
    };

    handleSngDialogClose = (confim) => {
        this.setState({ dialog_del_sngdevice_open: false });
        if (confim) {
            this.closeDrawer();
            this.MapDevicesJSCommands.update('delete_sng_device', this.state.selectedSngDeviceItem);
        }
    };

    getGroupRef = (group) => {
        return `${group.parent}#${group.id}`;
    }

    getGroupByRef = (ref) => {
        return this.state.groups.find((group) => {
            return this.getGroupRef(group) === ref;
        });
    }

    renderCardText = (device) => {
        const itemStyle = {
            lineHeight: '25px'
        };

        const itemHeadStyle = {
            width: '25%',
            display: 'inline-block',
            fontWeight: 600
        };

        const chipStyle = {
            display: 'inline-block',
            marginRight: '5px'
        };

        return (
            <React.Fragment>
                <div>
                    <p style={itemStyle}><span style={itemHeadStyle}>Description:</span>{device.device.description}</p>
                    <p style={itemStyle}><span style={itemHeadStyle}>Reference:</span>{device.device.reference}</p>
                    <p style={itemStyle}><span style={itemHeadStyle}>Type:</span>{device.device.type === 'sys2' ? 'SENSOR' : 'SMARTSPOT'}</p>
                </div>
                <div>
                    {Object.keys(device.groups).map(groupId => {
                        const group = this.getGroupByRef(groupId);
                        return group && <Chip key={groupId} style={chipStyle}>{group.name}</Chip>;
                    })}
                </div>
            </React.Fragment>
        );
    }


    render() {

        const { USER } = this.props;
        const site = USER.selectedSite;

        if (!site) return (<MaterialSpinner text="Loading Site..." x="14" />);

        const styles = {
            mapeditor: {
                minWidth: 500,
                minHeight: 600,
                width: '100%',
                height: '100%',
                position: 'absolute',
                right: 0,
                top: 0
            },
            paper: {
                padding: 15,
                marginBottom: 15,
                marginTop: 5,
            },
            leftButton: {
                marginRight: '20px',
                marginTop: '10px'
            },
            icon: {
                marginLeft: '10px',
                verticalAlign: 'middle'
            }
        };


        const selectedLength = this.state.selectedMultiDeviceItem && this.state.selectedMultiDeviceItem.devices ?
            this.state.selectedMultiDeviceItem.devices.length : 0;

        const selectedDevices = this.state.selectedMultiDeviceItem && this.state.selectedMultiDeviceItem.devices ?
            this.state.selectedMultiDeviceItem.devices : [];

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme)}  >
                <div style={styles.mapeditor}>
                    <div id={this.divID} style={styles.mapeditor}>

                    </div>

                    <Drawer width={400} openSecondary={true} open={this.state.showDrawer} >

                        <Paper zDepth={1} style={styles.paper} >


                            {this.state.multiDevicePopupMulSelect ?
                                <div>

                                    <h3>{`${selectedLength} device${selectedLength > 1 ? 's' : ''} selected`}</h3>

                                    <br />
                                    <br />
                                    <Checkbox
                                        label="Move on drag"
                                        checked={this.state.selectedMultiDeviceItem ?
                                            this.state.selectedMultiDeviceItem['moveSelected'] || false
                                            : false}
                                        onCheck={() => this.changeSelectedMultiDeviceItem(
                                            { target: { value: !this.state.selectedMultiDeviceItem['moveSelected'] } },
                                            'moveSelected',
                                            'moveSelecedDevices')}
                                    />
                                    <RaisedButton
                                        onMouseDown={(e) => this.startRotation(e, 'cw')}
                                        onMouseUp={() => this.endRotation()}
                                        style={styles.leftButton}
                                    >
                                        Rotate
                                        <RotateRightIcon style={styles.icon} />
                                    </RaisedButton>
                                    <RaisedButton
                                        onMouseDown={(e) => this.startRotation(e, 'acw')}
                                        onMouseUp={() => this.endRotation()}
                                        style={styles.leftButton}
                                    >
                                        Rotate
                                        <RotateLeftIcon style={styles.icon} />
                                    </RaisedButton>
                                    {this.state.selectType === 'rectangle' ?
                                        <RaisedButton
                                            onClick={(e) => this.flipDevices(e)}
                                            style={styles.leftButton}
                                        >
                                            Flip
                                            <FlipIcon style={styles.icon} />
                                        </RaisedButton>
                                        : ''}
                                    <br />
                                    <br />
                                    <Divider />
                                    <br />
                                    <RaisedButton label="Save" primary={true} onClick={this.moveSelectedSaveClick} />
                                    <RaisedButton label="Cancel" onClick={this.cancelMulClicked} style={{
                                        marginLeft: 20
                                    }} />
                                    <RaisedButton label="Delete" secondary={true} onClick={this.deleteMultyDeviceClicked} style={{ marginLeft: 150 }} />
                                    <br />
                                    <br />
                                    <br />
                                    {
                                        selectedDevices
                                            .sort((a, b) => a.device.name.localeCompare(b.device.name))
                                            .map(device => {
                                                return (
                                                    <Card key={device.device.reference}>
                                                        <CardHeader title={device.device.name} actAsExpander={true} showExpandableButton={true} />
                                                        <CardText expandable={true}>
                                                            {this.renderCardText(device)}
                                                        </CardText>
                                                    </Card>
                                                );
                                            })

                                    }



                                    <Dialog
                                        title="Confirm"
                                        actions={[
                                            <FlatButton
                                                key="multiDialogCancel"
                                                label="Cancel"
                                                primary={true}
                                                onClick={this.handleMulDialogClose.bind(null, false)}
                                            />,
                                            <FlatButton
                                                key="multiDialogConfirm"
                                                label="Confirm"
                                                primary={true}
                                                keyboardFocused={true}
                                                onClick={this.handleMulDialogClose.bind(null, true)}
                                            />,
                                        ]}
                                        modal={false}
                                        open={this.state.dialog_muldevice_open}
                                        onRequestClose={this.handleMulDialogClose}>
                                        Confirm Delete?
                                    </Dialog>


                                </div>
                                : null}

                            {this.state.multiDevicePopup ?
                                <div>

                                    <h4>Create Devices along path</h4>

                                    <TextField
                                        fullWidth={true}
                                        hintText="Name prefix for the device "
                                        floatingLabelText="Name"
                                        errorText={this.state.errorMulName}

                                        onChange={
                                            (e) => this.changeSelectedMultiDeviceItem(e, 'name')
                                        }
                                        value={
                                            this.state.selectedMultiDeviceItem ?
                                                this.state.selectedMultiDeviceItem['name']
                                                : ''
                                        }
                                    />

                                    <TextField
                                        fullWidth={true}
                                        hintText="Number as postfix for the device"
                                        floatingLabelText="From"
                                        errorText={this.state.errorMulFrom}

                                        onChange={
                                            (e) => this.changeSelectedMultiDeviceItem(e, 'from')
                                        }
                                        value={
                                            this.state.selectedMultiDeviceItem ?
                                                this.state.selectedMultiDeviceItem['from']
                                                : ''
                                        }
                                    />

                                    <TextField
                                        fullWidth={true}
                                        hintText="Number as postfix for the device "
                                        floatingLabelText="To"
                                        errorText={this.state.errorMulTo}

                                        onChange={
                                            (e) => this.changeSelectedMultiDeviceItem(e, 'to')
                                        }
                                        value={
                                            this.state.selectedMultiDeviceItem ?
                                                this.state.selectedMultiDeviceItem['to']
                                                : ''
                                        }
                                    />
                                    <RadioButtonGroup name="oddEven"
                                        defaultSelected={
                                            this.state.selectedMultiDeviceItem ?
                                                this.state.selectedMultiDeviceItem['oddEven']
                                                : 'All'
                                        }

                                        onChange={
                                            (e) => this.changeSelectedMultiDeviceItem(e, 'oddEven')
                                        }

                                    >
                                        <RadioButton
                                            value="All"
                                            label="All"
                                        />
                                        <RadioButton
                                            value="Odd"
                                            label="Odd Numbers"
                                        />
                                        <RadioButton
                                            value="Even"
                                            label="Even Numbers"
                                        />
                                    </RadioButtonGroup>


                                    <br />
                                    <br />
                                    {this.state.numberOfDevices}
                                    <br />
                                    <br />

                                    <Checkbox
                                        label="Reverse Order"
                                        checked={this.state.selectedMultiDeviceItem ?
                                            this.state.selectedMultiDeviceItem['reverse']
                                            : false}
                                        onCheck={() => this.changeSelectedMultiDeviceItem({ target: { value: !this.state.selectedMultiDeviceItem['reverse'] } }, 'reverse')}
                                    />

                                    <Checkbox
                                        label="Enable Dragging"
                                        checked={this.state.selectedMultiDeviceItem ?
                                            this.state.selectedMultiDeviceItem['move']
                                            : false}
                                        onCheck={() => this.changeSelectedMultiDeviceItem({ target: { value: !this.state.selectedMultiDeviceItem['move'] } }, 'move')}
                                    />

                                    <Checkbox
                                        label="Snapped To Road"
                                        checked={this.state.selectedMultiDeviceItem ?
                                            this.state.selectedMultiDeviceItem['snap']
                                            : false}
                                        onCheck={() => this.changeSelectedMultiDeviceItem({ target: { value: !this.state.selectedMultiDeviceItem['snap'] } }, 'snap')}
                                    />



                                    <br />

                                    <RaisedButton label="Place Devices" primary={true} onClick={this.createMulDeviceClicked} />

                                    <hr />

                                    <RaisedButton label="Save" primary={true} onClick={this.saveMulDevices} />

                                    <RaisedButton label="Discard" onClick={this.cancelMulClicked} style={{
                                        marginLeft: 20
                                    }} />




                                </div>
                                : null}

                            {this.state.singleDevicePopup ?
                                <div>

                                    <TextField
                                        fullWidth={true}
                                        hintText="Name of the device "
                                        floatingLabelText="Name"
                                        errorText={this.state.errorSngName}

                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'name')
                                        }
                                        value={
                                            this.state.selectedSngDeviceItem ?
                                                this.state.selectedSngDeviceItem['name']
                                                : ''
                                        }
                                    />

                                    <TextField
                                        fullWidth={true}
                                        hintText="Description"
                                        floatingLabelText="Description"
                                        errorText={this.state.errorSngDesc}

                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'description')
                                        }
                                        value={
                                            this.state.selectedSngDeviceItem ?
                                                this.state.selectedSngDeviceItem['description']
                                                : ''
                                        }
                                    />


                                    <TextField
                                        fullWidth={true}
                                        hintText="Sensor id or hardware reference eg: 255-002"
                                        floatingLabelText="Reference"
                                        errorText={this.state.errorSngsensor}
                                        disabled={true}

                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'sensor')
                                        }
                                        value={
                                            this.state.selectedSngDeviceItem ?
                                                this.state.selectedSngDeviceItem['sensor']
                                                : ''
                                        }
                                    />

                                    <DropDownMenu
                                        autoWidth={true}
                                        disabled={false}
                                        defaultValue={DEVICE_TYPE_SENSOR}
                                        value={this.state.selectedSngDeviceItem ?
                                            this.state.selectedSngDeviceItem['type'] : DEVICE_TYPE_SENSOR
                                        }
                                        onChange={
                                            (event, index, value) => this.changeSelectedSngDeviceItem({ target: { value: value } }, 'type')
                                        }>

                                        <MenuItem value={DEVICE_TYPE_SENSOR} primaryText="Sensor" />
                                        <MenuItem value={DEVICE_TYPE_SYS2_MART_SPOT} primaryText="SmartSpot" />
                                        <MenuItem value={DEVICE_TYPE_COUNTER} primaryText="Counter" />
                                        <MenuItem value={DEVICE_TYPE_PARKEON_PAYMENT_MACHINE} primaryText="Parkeon Payment Machine" />
                                        <MenuItem value={DEVICE_TYPE_TICKET_VENDING_MACHINE} primaryText="Ticket Vending Machine" />
                                        <MenuItem value={DEVICE_TYPE_MAV_CAMERA} primaryText="License Plate Recognition Camera" />
                                    </DropDownMenu>

                                    <Checkbox
                                        label="Enable Dragging"
                                        checked={this.state.selectedSngDeviceItem ?
                                            this.state.selectedSngDeviceItem['move']
                                            : false}
                                        onCheck={() => this.changeSelectedSngDeviceItem({ target: { value: !this.state.selectedSngDeviceItem['move'] } }, 'move')}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        hintText="Latitude/Longitude"
                                        floatingLabelText="Latitude/Longitude"
                                        errorText={this.state.errorLocation}
                                        type="string"
                                        disabled={true}
                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'location')
                                        }
                                        value={this.state.selectedSngDeviceItem ?
                                            this.state.selectedSngDeviceItem['location']
                                            : ''
                                        }
                                    />

                                    <TextField
                                        fullWidth={true}
                                        hintStyle={{ fontSize: 12 }}
                                        hintText="Location Descriptor"
                                        floatingLabelText="Location Descriptor"
                                        errorText={this.state.errorLocationDescriptor}
                                        type="string"
                                        disabled={false}
                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'locationDescriptor')
                                        }
                                        value={this.state.selectedSngDeviceItem ?
                                            this.state.selectedSngDeviceItem['locationDescriptor']
                                            : ''
                                        }
                                    />

                                    <TextField
                                        fullWidth={false}
                                        hintText="Device capacity value"
                                        floatingLabelText="Maximum Vacancy"
                                        errorText={this.state.errorSngMaximum}
                                        type="number"
                                        disabled={this.state.selectedSngDeviceItem['type'] !== DEVICE_TYPE_COUNTER}
                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'maximum')
                                        }
                                        value={this.state.selectedSngDeviceItem ?
                                            this.state.selectedSngDeviceItem['maximum']
                                            : ''
                                        }
                                    />

                                    <TextField
                                        fullWidth={true}
                                        hintText="Serial Number"
                                        floatingLabelText="Serial Number"
                                        errorText={this.state.errorSerialNumber}
                                        type="string"
                                        disabled={this.state.selectedSngDeviceItem['type'] !== DEVICE_TYPE_MAV_CAMERA && this.state.selectedSngDeviceItem['type'] !== DEVICE_TYPE_PARKEON_PAYMENT_MACHINE && this.state.selectedSngDeviceItem['type'] !== DEVICE_TYPE_TICKET_VENDING_MACHINE}
                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'serialNumber')
                                        }
                                        value={this.state.selectedSngDeviceItem ?
                                            this.state.selectedSngDeviceItem['serialNumber']
                                            : ''
                                        }
                                    />

                                    <TextField
                                        fullWidth={true}
                                        hintText="Counter Type"
                                        floatingLabelText="Counter Type"
                                        errorText={this.state.errorSngCounter}
                                        type="string"
                                        disabled={this.state.selectedSngDeviceItem['type'] !== DEVICE_TYPE_COUNTER}
                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'counterType')
                                        }
                                        value={this.state.selectedSngDeviceItem ?
                                            this.state.selectedSngDeviceItem['counterType']
                                            : ''
                                        }
                                    />

                                    <h1><strong>Camera Configurations</strong></h1>

                                    <DropDownMenu
                                        autoWidth={true}
                                        disabled={this.state.selectedSngDeviceItem['type'] !== DEVICE_TYPE_MAV_CAMERA}
                                        value={this.state.selectedSngDeviceItem &&
                                            this.state.selectedSngDeviceItem['cameraConfig'] &&
                                            this.state.selectedSngDeviceItem['cameraConfig'].pointingDirection ?
                                            this.state.selectedSngDeviceItem['cameraConfig'].pointingDirection : CAMERACONFIG_POINTINGOUTSIDE}
                                        onChange={
                                            (e, index, value) => this.changeSelectedSngDeviceItem({ target: { value: { pointingDirection: value } } }, 'cameraConfig')
                                        }>
                                        <MenuItem value={CAMERACONFIG_POINTINGOUTSIDE} primaryText="Camera points OUT of Carpark" />
                                        <MenuItem value={CAMERACONFIG_POINTINGINSIDE} primaryText="Camera points INTO the Carpark" />
                                    </DropDownMenu>

                                    <Checkbox
                                        labelPosition='left'
                                        labelStyle={{ fontSize: 14 }}
                                        label="Validation Camera (WARNING: Do not check this if it is not being used for exempting all vehicles travelling in and out of this camera)"
                                        autoWidth={true}
                                        disabled={this.state.selectedSngDeviceItem['type'] !== DEVICE_TYPE_MAV_CAMERA}
                                        checked={this.state.selectedSngDeviceItem &&
                                            this.state.selectedSngDeviceItem['cameraConfig'] &&
                                            this.state.selectedSngDeviceItem['cameraConfig'].validationCamera ?
                                            this.state.selectedSngDeviceItem['cameraConfig'].validationCamera === 'true' : false}
                                        onCheck={(e, value) =>
                                            this.changeSelectedSngDeviceItem({ target: { value: { validationCamera: value.toString() } } }, 'cameraConfig')
                                        }>
                                    </Checkbox>

                                    <TextField
                                        fullWidth={true}
                                        hintStyle={{ fontSize: 12 }}
                                        hintText="Contravention Management System Notes"
                                        floatingLabelText="CMS Notes"
                                        errorText={this.state.errorCMSNotes}
                                        type="string"
                                        disabled={false}
                                        onChange={
                                            (e) => this.changeSelectedSngDeviceItem(e, 'cmsNotes')
                                        }
                                        value={this.state.selectedSngDeviceItem ?
                                            this.state.selectedSngDeviceItem['cmsNotes']
                                            : ''
                                        }
                                    />

                                    <DropDownMenu
                                        autoWidth={true}
                                        disabled={!this.deviceNameWithShadowed()}        // user need to put shadow in the name, to ensure it's visible to UI
                                        value={this.state.selectedSngDeviceItem &&
                                            this.state.selectedSngDeviceItem['shadow'] ?
                                            this.state.selectedSngDeviceItem['shadow'] : 'false'}
                                        onChange={
                                            (e, index, value) => {
                                                this.changeSelectedSngDeviceItem({ target: { value: value } }, 'shadow');
                                            }
                                        }>
                                        <MenuItem value="false" primaryText="Physical Device (default)" />
                                        <MenuItem value="true" primaryText="Shadowed Device" />
                                    </DropDownMenu>

                                    <br />
                                    <br />
                                    <br />

                                    <RaisedButton label="Save" primary={true} onClick={this.createSingleDeviceClicked} />
                                    <RaisedButton label="Cancel" onClick={this.cancelMulClicked} style={{ marginLeft: 5 }} />
                                    <RaisedButton label="Delete" secondary={true} onClick={this.deleteSingleDeviceClicked} style={{ marginLeft: 25 }} />

                                    <Dialog
                                        title="Confirm"
                                        actions={[
                                            <FlatButton
                                                key="singleDialogCancel"
                                                label="Cancel"
                                                primary={true}
                                                onClick={this.handleSngDialogClose.bind(null, false)}
                                            />,
                                            <FlatButton
                                                key="singleDialogConfirm"
                                                label="Confirm"
                                                primary={true}
                                                keyboardFocused={true}
                                                onClick={this.handleSngDialogClose.bind(null, true)}
                                            />,
                                        ]}
                                        modal={false}
                                        open={this.state.dialog_del_sngdevice_open}
                                        onRequestClose={this.handleSngDialogClose}>
                                        Confirm Delete?
                                    </Dialog>

                                </div>




                                : null}

                        </Paper>
                    </Drawer>

                    <Snackbar
                        open={this.state.snakbaropen}
                        message={this.state.snak}
                        autoHideDuration={5000}
                        onRequestClose={this.handleSnackBarRequestClose}

                    />

                </div>

            </MuiThemeProvider>
        );
    }


}

export default PlaceDevices;
