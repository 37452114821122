import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { config } from './config/firebaseAuthConfig';
import Login from './login/login';
import AppConfig from './config/AppConfig';
import AppStorage from './config/AppStorage';

import ProtectedContent from './ProtectedContent';
import * as API from './api/api';

import './css/index.css';
import Security from './config/Security';


var firebase = require('firebase');
var firebaseui = require('firebaseui');
require('firebase/auth');
require('firebase/database');

class App extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;

        let year = 2017;
        let thisYear = new Date().getFullYear();

        if (thisYear > year) {
            year = `${year}-${thisYear} `;
        }


        this.state = {
            USER: null,
            APP: {
                initialize: false
            },
            copyright: {
                name: `Â© ${year}`,
                companyName: ' Limited',
                text: `Â© ${year}  Limited. All rights reserved.`,
                url: 'https://www..com/'
            },
            token: null,
        };

        const app = firebase.default.initializeApp(config);
        AppConfig.setFirebaseApp(app);

        this.authUi = new firebaseui.auth.AuthUI(firebase.default.auth());

        firebase.default.auth().onAuthStateChanged(async (user) => {
            if (user !== null) {
                await this.getToken();
                await this.checkUserPermission(user);
                this.setAppInit(true);
            }
            this.updateUser(user);
        });
    }

  checkUserPermission = async (user) => {
      const result = await Security.hasAppPermission(user);
      if(!result){
          await Security.logout();
      }
  }

  updateUser = (user) => {
      this.updateState({ USER: user });
  }

  logout = () => {
      this.updateState({ USER: null, token: null });
      Security.logout();
      window.location.href = '/';

  }

  updateState = (state) => {
      if (!this.mounted) return;
      this.setState({ ...state });
  };

  setAppInit = (isInit) => {
      this.updateState(() => (({ APP: { initialize: isInit } })));
  }
  componentDidMount() {
      this.mounted = true;
      AppConfig.setTitle();
  }

  componentWillUnmount() {
      this.mounted = false;
  }


  validateUserSitesWithLocalCache = (token, userId) => {
      API.getUserSites(token).then((r) => {
          let userSites = AppStorage.getUserSites(userId);
          if (JSON.stringify(userSites) !== JSON.stringify(r)) {
              AppStorage.clearUserSites();
          }
      });
  }

  loadUserSites = (token, userId) => {
      let userSites = AppStorage.getUserSites(userId);
      let promise = userSites ? Promise.resolve(userSites) : API.getUserSites(token);

      return promise.then((r) => {
          AppConfig.setUserSites(r);
          AppStorage.setUserSites(userId, r);
          return Promise.resolve();
      });
  }


  getToken = async () => {
      let self = this;
      let currentUser = firebase.default.auth().currentUser;
      if (currentUser) {
          const idToken = await firebase.default.auth().currentUser.getIdToken(/* forceRefresh */ true);
          await self.loadUserSites(idToken, currentUser.id);
          window.__Token = idToken;
          self.setToken(idToken);
          self.timeout = 900000; // 15 min
          self.validateUserSitesWithLocalCache(idToken, currentUser.id);
      }
  }

  setToken = (idToken) => {
      if (this.mounted === false) {
          return;
      }
      this.updateState( { token: idToken });
  }

  setUserOrgRegSite = (org, reg, site) => {
      if (this.mounted === false) {
          return;
      }
      if (!this.state.USER) return;
      const USER = this.state.USER;
      USER.selectedOrg = org;
      USER.selectedRegion = reg;
      USER.selectedSite = site;

      this.updateState({USER} );
  }


  onOrgChange = (selectedOrg) => {
      if (this.mounted === false) {
          return;
      }
      this.setUserOrgRegSite(selectedOrg, null, null);
  }

  onRegionChange = (selectedRegion) => {
      if (this.mounted === false) {
          return;
      }
      if (!this.state.USER) {
          return;
      }
      this.setUserOrgRegSite(this.state.USER.selectedOrg, selectedRegion, null);
  }

  onsiteChange = (selectedSite) => {
      if (this.mounted === false) {
          return;
      }
      if (!this.state.USER) {
          return;
      }
      this.setUserOrgRegSite(this.state.USER.selectedOrg, this.state.USER.selectedRegion, selectedSite);
  }

  render() {
      // Is user authenticated
      const isAuthenticated = this.state.USER !== null;
      // use isAuthenticated and has permission
      const userHasPermissions = true;

      return (
          <Router>
              <div style={{
                  height: '100%'
              }}>
                  <Switch>
                      <Route exact path="/login" render={() => <Login USER={this.state.USER} onUpdate={this.updateUser} copyright={this.state.copyright} authUi={this.authUi} />} />

                      <PrivateRoute path="/"
                          component={ProtectedContent}
                          userHasPermissions={userHasPermissions}
                          isAuthenticated={isAuthenticated}
                          USER={this.state.USER}
                          logout={this.logout}
                          onchildUpdate={this.childUpdate}
                          copyright={this.state.copyright}
                          token={this.state.token}
                          onOrgChange={this.onOrgChange}
                          onRegionChange={this.onRegionChange}
                          onsiteChange={this.onsiteChange}

                      />

                  </Switch>
              </div>
          </Router>
      );

  }
}

const PrivateRoute = ({ component: Component, isAuthenticated, userHasPermissions, ...rest }) => {
    let pathName = encodeURIComponent(window.location.pathname);
    let redirect = pathName === '' ? null : ('?redirect=' + pathName);

    // redirect if user is not isAuthenticated = false or userHasPermissions = false
    let redirectToLogin = '/login' + redirect;

    let route = <Route {...rest} render={props => {

        if (isAuthenticated === false) return <Redirect to={redirectToLogin} />;

        if (userHasPermissions) return <Component {...props} {...rest} />;

        // users without permission go here
        return <Redirect to='/welcome' />;
    }} />;
    return route;
};

export default App;
