import firebase from 'firebase';
import 'firebase/auth';

import AppStorage from './AppStorage';
import { getMe } from '../api/api';

function shouldAuthorize(user) {
  if (!user || Object.keys(user).length === 0) {
    return false;
  }
  const scPermission = user.permissions.find((it) => it.groupRef === 'smartparking#smartcloud');
  let isGlobal = scPermission && scPermission.roles.some((it) => ['Super Global', 'Global'].includes(it));

  if (!isGlobal && scPermission && scPermission.roles.includes('Regional Global')) {
    // check if user has global access to an organization
    isGlobal = Boolean(user.permissions.find((it) => it.organization === 'smartparking' && it.type === 'organization' && it.roles.includes('Global')));
  }

  const appPermission = user.permissions.find((it) => it.groupRef === 'app#siteplanner');
  const hasAppRoles = appPermission && (appPermission.roles.includes('User') || appPermission.roles.includes('Global'));

  const orgId = AppStorage.getSelectedSite().org;
  const orgPermission = orgId ? user.permissions.find((it) => it.groupRef === orgId && it.type === 'organization' && it.organization === orgId) : undefined;
  const hasUserRoles = orgPermission && (orgPermission.roles.includes('Operations') || orgPermission.roles.includes('Team Lead'));

  return isGlobal || hasAppRoles || hasUserRoles;
}


async function hasAppPermission(user) {
  if(!user) return;

  let token = window.__token;

  if (!token) {
    token = await firebase.default.auth().currentUser.getIdToken(true);
  }
  const me = await getMe(token);
  user.permissions = me.permissions;

  return shouldAuthorize(user);
}

async function logout(){
  await firebase.auth().signOut();
}

export default {hasAppPermission, logout};
