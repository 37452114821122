import React from 'react';
import Select from 'react-select';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-select/dist/react-select.css';
import PropTypes from 'prop-types';


import { getRegionsFromCache } from '../api/api';
import AppStorage from '../config/AppStorage';
import AppConfig from '../config/AppConfig';

class RegionSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            selected: null,
            loading: false,
            disabled: true,
        };

        this.selectedOrg = null;

        this.onRegionChange = this.onRegionChange.bind(this);
    }

    onRegionChange(val) {
        let USER = this.props.USER;
        let value = { selected: !val ? null : val.value };

        // save to session storage
        let reloadPage = this.state.selected === null ? false : true;

        let fromStorage = AppStorage.getSelectedSite();
        let site = value.selected === null ? null :
            value.selected === fromStorage.reg ? fromStorage.site : null;

        AppStorage.setSelectedSite(USER.selectedOrg, value.selected, site);

        this.setState(() => value);

        if (value.selected === null) return;

        if (!USER) return;

        USER.selectedRegion = val.value;
        // tell parent
        this.props.onRegionChange(USER.selectedRegion);
        if(reloadPage){
            AppStorage.clearUserSites();
            window.location.href = window.location.href.replace('#','');
        }
    }

    componentDidMount() {
    // get last used region and use as default selection when org selected event triggers
    }

    loadRegions() {
        this.setState(() => {
            return { regions: [], selected: null, loading: true };
        });
        // get last used region from local or session state

        let regions = [];

        return getRegionsFromCache().then(regionsResult => {

            AppConfig.setRegions(regionsResult);

            regionsResult.sort((a, b) => {
                return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0
                );
            });

            regionsResult.forEach(region => {
                const { regionId, name } = region;

                regions.push({
                    value: regionId,
                    label: name,
                    clearableValue: false,
                });
            });



            const sessionStSite = AppStorage.getSelectedSite();

            let selected = null;


            // empty result
            if (regions.length === 0) {
                regions.push({
                    value: 'empty',
                    label: 'No Regions',
                    clearableValue: false,
                });
            } else {
                selected = regions.find(function (reg) {
                    return sessionStSite.reg === reg.value;
                });
            }

            this.setState(function () {
                return {
                    regions: regions,
                    loading: false,
                    disabled: regions.length <= 1,
                };
            });

            if (this.state.selected === null) {
                selected = selected || regions[0];
                this.onRegionChange(selected);
            }
        });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.USER.selectedOrg && nextProps.USER.selectedOrg !== this.selectedOrg) {
            this.loadRegions();
            this.selectedOrg = nextProps.USER.selectedOrg;
        }
    }

    render() {
        return (
            <div>
                <Select
                    name="region-selector"
                    disabled={this.state.disabled}
                    options={this.state.regions}
                    isLoading={this.state.loading}
                    value={this.state.selected}
                    clearable={false}
                    placeholder={'Regions...'}
                    onChange={this.onRegionChange} />
            </div>
        );
    }
}

export default RegionSelector;


RegionSelector.defaultProps = {
    regionChanged: function () { }
};

RegionSelector.propTypes = {
    regionChanged: PropTypes.func.isRequired,
};
