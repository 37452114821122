import React from 'react';
import Select from 'react-select';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-select/dist/react-select.css';
import PropTypes from 'prop-types';

import { getOrgsFromCache } from '../api/api';
import AppStorage from '../config/AppStorage';
import AppConfig from '../config/AppConfig';


class OrgSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orgs: [],
            selected: null,
            loading: false,
            disabled: true,
        };
        this.orgsLoaded = false;
        this.onOrgChange = this.onOrgChange.bind(this);
    }

    onOrgChange(val) {
        if (this.mounted === false) return;

        let value = { selected: !val ? null : val.value };
        // save to session storage
        let reloadPage = this.state.selected === null ? false : true;

        let fromStorage = AppStorage.getSelectedSite();
        let reg = value.selected === null ? null :
            value.selected === fromStorage.org ? fromStorage.reg : null;
        let site = reg === null ? null : fromStorage.site;

        AppStorage.setSelectedSite(value.selected, reg, site);

        this.setState(() => value);

        if (value.selected === null) return;

        let USER = this.props.USER;
        if (!USER) return;

        USER.selectedOrg = val.value;
        // tell parent
        this.props.onOrgChange(USER.selectedOrg);
        if(reloadPage){
            AppStorage.clearUserSites();
            window.location.href = window.location.href.replace('#','');
        }
    }

    componentDidMount() {
        const self = this;
        this.mounted = true;
        if (this.props.token) {
            self.loadOrgs();
            this.orgsLoaded = true;
        }
    }


    componentWillUnmount() {
        this.mounted = false;
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.token && this.orgsLoaded === false) {
            this.loadOrgs();
            this.orgsLoaded = true;
        }
    }

    loadOrgs() {
    // get last used org from local or session state

        this.setState(() => {
            return { loading: true };
        });

        let orgs = [];
        getOrgsFromCache().then(orgsResult => {
            AppConfig.setOrgs(orgsResult);

            orgsResult.sort((a, b) => {
                return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
            });

            orgsResult.forEach(org => {
                const { orgId, name } = org;

                orgs.push({
                    value: orgId,
                    label: name,
                    clearableValue: false,
                });
            });



            const sessionStSite = AppStorage.getSelectedSite();

            let selectedOrg = null;

            // empty result
            if (orgs.length === 0) {
                orgs.push({
                    value: 'empty',
                    label: 'No Orgs',
                    clearableValue: false,
                });
            } else {
                selectedOrg = orgs.find(function (org) {
                    return sessionStSite.org === org.value;
                });
            }

            if (this.mounted === false) return;


            this.setState(function () {
                return {
                    orgs: orgs,
                    loading: false,
                    disabled: orgs.length <= 1,
                };
            });

            if (this.state.selected === null) {
                selectedOrg = selectedOrg || orgs[0];
                this.onOrgChange(selectedOrg);
            }
        });
    }

    render() {
        return (
            <div>
                <Select
                    name="org-selector"
                    disabled={this.state.disabled}
                    options={this.state.orgs}
                    isLoading={this.state.loading}
                    value={this.state.selected}
                    clearable={false}
                    placeholder={'Organizations...'}
                    onChange={this.onOrgChange} />
            </div>
        );
    }
}

export default OrgSelector;


OrgSelector.defaultProps = {
    orgChanged: function () { }
};

OrgSelector.propTypes = {
    orgChanged: PropTypes.func.isRequired,
};
