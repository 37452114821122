import React from 'react';
import { Redirect } from 'react-router-dom';
import FirebaseUI from './FirebaseUI';
import firebase from 'firebase';
import AppConfig from '../config/AppConfig';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import '../css/colors.css';
import '../css/login.css';
import Security from '../config/Security';
import Snackbar from 'material-ui/Snackbar';


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            snackbarToggle: false,
            snackbarAutoHideDuration: 5000,
            snackbarMessage: '',
            snackbarOpen: false,
        };

        firebase.auth().onAuthStateChanged((user) => {
            this.setUser(user);
        });

    }

    componentDidMount() {
        this.mounted = true;
        AppConfig.setTitle('Login');
    }
    componentWillUnmount() {
        this.mounted = false;
        AppConfig.setTitle('Site Planner');

    }

  updateState = (state) => {
      if (!this.mounted) return;
      this.setState({ ...state });
  };

  setUser = async (user) => {
      const result = await Security.hasAppPermission(user);
      let validAccess = true;
      let snackbarMessage = '';

      if (result === false) {
          validAccess = false;
          snackbarMessage = 'You don\'t have enough permission to access SitePlanner';
      }

      if (user && user.emailVerified === false) {
          validAccess = false;
          snackbarMessage = 'Email verification failed';
      }

      if (validAccess === false) {
          await Security.logout();
          this.updateState({ snackbarMessage, snackbarOpen: true });
          return;
      }
      if (user) {
          this.props.onUpdate(user);
          this.updateState({ redirectToReferrer: true });
      }
  }

  handleRequestClose = () => {
      this.setState({
          snackbarOpen: false,
      });
  };

  render() {
      const { redirectToReferrer } = this.state;
      if (redirectToReferrer) {
          return (
              <Redirect to='/' />
          );
      }



      const topStyles = {
          height: '100%'
      };

      return (
          <MuiThemeProvider>
              <div className='bg-black' style={topStyles}>
                  <ul className="cb-slideshow">
                      <li><span>Image 01</span><div><h3></h3></div></li>
                      <li><span>Image 02</span><div><h3></h3></div></li>
                      <li><span>Image 03</span><div><h3></h3></div></li>
                      <li><span>Image 04</span><div><h3></h3></div></li>
                      {/* <li><span>Image 05</span><div><h3></h3></div></li> */}
                      {/* <li><span>Image 06</span><div><h3></h3></div></li> */}
                  </ul>
                  <div className="fb-ui-wrapper-main">
                      <div className="fb-ui-title">
                          <a href="/">
                              <img src="/pwa-192x192.png" alt="SmartCloud" className="fb-login-logo" />
                          </a>
                      </div>
                      <h3 className="fb-login-name">Site Planner</h3>
                      <FirebaseUI onSignIn={this.setUser} authUi={this.props.authUi} />
                  </div>
                  <Snackbar
                      open={this.state.snackbarOpen}
                      message={this.state.snackbarMessage}
                      action="Ok"
                      autoHideDuration={this.state.snackbarAutoHideDuration}
                      onRequestClose={this.handleRequestClose}
                  />
              </div>
          </MuiThemeProvider>
      );
  }
}

export default Login;