import React from 'react';
import OrgSelector from './OrgSelector';
import RegionSelector from './RegionSelector';
import SiteSelector from './SiteSelector';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import firebase from 'firebase';

import AppStorage from '../config/AppStorage';
import Helpers from '../utils/Helpers';
import '../css/avatar.css';

export default class TopRightNavBar extends React.Component {
    state = {
        open: false,
        userHasNoProfilePic: true, displayName: '', initials: '', email: ''
    };

    componentDidMount() {
        this.mounted = true;
        this.checkImage();
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    componentWillReceiveProps() {
        this.checkImage();
    }

    updateState = (obj, callback = function () { }) => {
        if (this.mounted === false) return;
        this.setState({ ...obj }, callback);
    }

    handleClick = e => {
        e.preventDefault();

        this.updateState({ open: true, anchorEl: e.currentTarget });
    };

    handleRequestClose = () => {
        this.updateState({ open: false });
    };

    handleLogout = async () => {
        AppStorage.clearUserSites();
        await firebase.auth().signOut();
    };

    truncateString = (str, num) => {
        if (str.length > num && num > 3) {
            return str.slice(0, num - 3) + ' ...';
        }
        return str;
    }

    checkImage = () => {
        const { USER } = this.props;
        if (!USER) return;

        let photoURL = USER.photoURL;
        Helpers.is404(photoURL).then((userHasNoProfilePic) => {
            let displayName = USER.displayName === '' ? USER.eamil : USER.displayName;
            let initials = Helpers.getUserInitials(displayName);
            this.updateState({ userHasNoProfilePic, initials });
        });
    }

    render() {
        const { USER } = this.props;
        const { displayName = '', photoURL = '', email = '' } = USER;
        const { userHasNoProfilePic, initials } = this.state;

        const styles = {
            width: 600,
            padding: '5px',
            position: 'relative'
        };

        const itemStyle = {
            display: 'inline-block',
            width: 145,
            marginRight: 5
        };

        const avatarStyle = {
            width: 24
        };

        const imgBtnStyle = {
            width: 34,
            height: 34,
            borderRadius: 17
        };

        const primaryStyle = {
            verticalAlign: 'top',
            position: 'absolute',
            top: '-8px',
            fontSize: '0.9em'
        };

        const secondaryStyle = {
            color: 'grey',
            fontSize: '0.8em',
            float: 'none !important',
            position: 'relative',
            top: '8px'
        };

        const fontStyle = {
            fontSize: 24
        };

        return (
            <div style={styles}>
                <div style={itemStyle}>
                    <OrgSelector USER={USER} onOrgChange={this.props.onOrgChange} token={this.props.token} />
                </div>
                <div style={itemStyle}>
                    <RegionSelector USER={USER} onRegionChange={this.props.onRegionChange} token={this.props.token} />
                </div>
                <div style={itemStyle}>
                    <SiteSelector USER={USER} onsiteChange={this.props.onsiteChange} token={this.props.token} />
                </div>
                <div style={{ ...itemStyle, right: -20 }}>
                    {userHasNoProfilePic && initials ? (<div className="user-avatar" onClick={this.handleClick} >
                        <div className="user-avatar__inner">
                            <span className="user-initials">{initials}</span>
                        </div>
                    </div>) : <img onClick={this.handleClick} src={USER.photoURL} alt="" style={imgBtnStyle} />}
                    <Popover
                        open={this.state.open}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
                        targetOrigin={{ horizontal: 'middle', vertical: 'top' }}
                        onRequestClose={this.handleRequestClose}
                    >
                        <Menu>
                            {userHasNoProfilePic ?
                                (<div className="user-avatar-wrapper">
                                    <div className="user-avatar" >
                                        <div className="user-avatar__inner">
                                            <span className="user-initials">{initials}</span>
                                        </div>
                                    </div>

                                    <div className="gb_Hb nopicadjest">
                                        <div className="gb_Pb">{displayName}</div>
                                        <div className="gb_Qb">{email}</div>
                                    </div>

                                </div>)
                                : <MenuItem
                                    primaryText={<div style={primaryStyle}>{displayName}</div>}
                                    secondaryText={<div style={secondaryStyle}>{email}</div>}
                                    leftIcon={<img style={avatarStyle} alt={displayName} src={photoURL} />}
                                />}
                            <MenuItem
                                primaryText="Sign out"
                                onClick={this.handleLogout}
                                leftIcon={<i style={fontStyle} className="fa fa-sign-out" />}
                            />
                        </Menu>
                    </Popover>
                </div>
            </div>
        );
    }
}
