import React from 'react';

import { baseURL, API_KEY } from '../config/settings';
import AppConfig from '../config/AppConfig';


import Drawer from 'material-ui/Drawer';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';
import Snackbar from 'material-ui/Snackbar';
import { Card, CardHeader, CardText } from 'material-ui/Card';

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import Chip from 'material-ui/Chip';
import * as API from '../api/api';

import MaterialSpinner from '../loading/material-spinner';
import { SelectField } from 'material-ui';

export default class GroupDevices extends React.Component {

    constructor(props) {
        super(props);
        this.divID = '__tagMapHolder__';

        this.mounted = false;
        this.state = {
            showDrawer: false,
            groups: [],
            selectedGroups: [],
            displayLevelItems: [],
            showStreeView: false,
            snakbaropen: false,
            snak: ''
        };

        this.styles = {
            chip: {
                margin: 4,
            },
            wrapper: {
                display: 'flex',
                flexWrap: 'wrap',
            },
        };

        const self = this;

        window.TagDevicesJSCommands = function () {
            self.TagDevicesJSCommands = this;

        };

        window.TagDevicesJSCommands.prototype.command = function (command, object) {

            if (command === 'open_drawer') {

                // console.log(object);

                let devices = object.devices;
                let groups = object.groups;
                let deviceLocation = object.deviceLocation;

                let items = self.createGroupItems(groups);


                let PanoramaOptions = deviceLocation ? {
                    position: { lat: deviceLocation.lat, lng: deviceLocation.long },
                    pov: { heading: 165, pitch: 0 },
                    zoom: 1
                } : null;


                self.updateState({
                    showStreeView: typeof deviceLocation !== undefined,
                    groups: groups,
                    menuItems: items,
                    devices: devices,
                    isSaving: false,
                    deviceLocation: deviceLocation,
                    PanoramaOptions:PanoramaOptions

                });

                let selectedGroups = self.getSelectedGroups(devices, groups);
                self.updateState({
                    selectedGroups: selectedGroups,
                    removeGroups: [],
                    addedGroups: [].concat(selectedGroups),
                });
                self.openDrawer();
            }
            if (command === 'close_drawer') {
                self.closeDrawer();
            }

            if (command === 'snakbar_message') {
                self.showSnakBar(object);
            }

        };

        window.TagDevicesJSCommands.prototype.onReceive = function (callback) {
            this.onReceive = callback;
        };
        window.TagDevicesJSCommands.prototype.update = function (obj) {
            this.onReceive(obj);
        };

    }

    showSnakBar = (message) => {
        this.setState({ snakbaropen: true, snak: message });
        setTimeout(() => {
            this.setState({ snakbaropen: false, snak: '' });
        }, 5001);
    }

    getGroupRef = (group) => {
        return `${group.group.parent}#${group.group.id}`;
    }

    getGroupByRef = (ref) => {
        return this.state.groups.find((group) => {
            return this.getGroupRef(group) === ref;
        });
    }

    getSelectedGroups = (devices, groups) => {

        let commonGroups = {};
        let groupRefsArray = groups.map((group) => {
            return this.getGroupRef(group);
        });

        let lengthCheck = devices.length > 1 ? devices.length : 0;

        devices.forEach((device) => {
            if (!device.groups) return;

            for (let groupRef in device.groups) {

                if (device.groups[groupRef] === 0) {
                    continue;
                }

                let found = groupRefsArray.find((ref) => {
                    return ref === groupRef;
                });

                if (found) {
                    commonGroups[found] = commonGroups[found] ? commonGroups[found] + 1 : 1;
                }
            }
        });

        let list = [];

        for (let ref in commonGroups) {
            if (commonGroups[ref] >= lengthCheck) {
                let group = this.getGroupByRef(ref);
                if (group)
                    list.push(group);
            }
        }
        return list;
    }

    getGroup = (groupKey) => {
        return this.state.groups.find((group) => {
            return group.group.key === groupKey;
        });
    }

    createChip = (groupKey) => {
        let group = this.getGroup(groupKey);
        const { selectedGroups, addedGroups } = this.state;


        let found = selectedGroups.find((grp) => {
            return (grp.group.key === groupKey);
        });

        if (!found && group) {
            selectedGroups.push(group);
            addedGroups.push(group);
        }

        this.setState({ selectedGroups });
    }

    createGroupItems = (groups) => {
        const sectorItems = [], lotItems = [], timeLimitItems = [], buildingItems = [], levelItems = [];
        groups.forEach((group) => {
            if (group.group.type === 'sector') {
                sectorItems.push(<MenuItem value={group.group} key={group.group.key} primaryText={group.group.name} />);
            } else if (group.group.type === 'lot') {
                lotItems.push(<MenuItem value={group.group} key={group.group.key} primaryText={group.group.name} />);
            } else if (group.group.type === 'timeLimit') {
                timeLimitItems.push(<MenuItem value={group.group} key={group.group.key} primaryText={group.group.name} />);
            } else if (group.group.type === 'building') {
                buildingItems.push(<MenuItem value={group.group} key={group.group.path} primaryText={group.group.name} />);
            } else if (group.group.type === 'buildingLevel') {
                levelItems.push(<MenuItem value={group.group} key={group.group.path} primaryText={group.group.name} />);
            }
        });
        return {sectorItems, lotItems, timeLimitItems, buildingItems, levelItems};
    }

    updateState = (state) => {
        this.setState(state);
    }

    openDrawer = () => {
        this.updateState({ showDrawer: true });
    }
    closeDrawer = () => {
        this.updateState({ showDrawer: false });
    }

    componentDidMount() {
        const { USER } = this.props;
        const org = USER.selectedOrg;
        const reg = USER.selectedRegion;
        const site = USER.selectedSite;
        this.selectedSite = site;

        if (org && reg && site) {
            this.loadMappingTool(USER, this.props);
        }



        this.mounted = true;

    }

    componentWillUnmount() {
        this.mounted = false;
    }



    loadMappingTool = (USER, props) => {
        const { token } = props;
        const org = USER.selectedOrg;
        const reg = USER.selectedRegion;
        const site = USER.selectedSite;

        this.selectedSite = site;

        let divID = this.divID;

        let location = AppConfig.getSiteLocation(site);

        AppConfig.loadScript('/js/mapping/tag/index.js', () => {
            if (window.TagMapping) {
                this.TagMapping = new window.TagMapping(divID, token, org, reg, site, baseURL, API_KEY);
                this.TagMapping.setLocation(location);
                this.TagMapping.init();
            }
        });
    }


    componentWillReceiveProps(nextProps) {

        const org = nextProps.USER.selectedOrg;
        const reg = nextProps.USER.selectedRegion;
        const site = nextProps.USER.selectedSite;

        if (!org || !reg || !site) {
            return;
        }

        if (this.selectedSite !== site) {
            this.loadMappingTool(nextProps.USER, nextProps);
        }

        this.selectedSite = nextProps.USER.selectedSite;
    }



    saveFromClicked = () => {
        const { token, USER } = this.props;
        const { addedGroups, removeGroups, devices } = this.state;

        const org = USER.selectedOrg;
        const reg = USER.selectedRegion;
        const site = USER.selectedSite;

        this.TagDevicesJSCommands.update('show_block_ui');
        this.setState({ isSaving: true });

        let serverPromise = [];
        // add groups to all devices
        let groupsToAdd = [];

        groupsToAdd.push({ id: org, parent: org, type: 'organization' });
        groupsToAdd.push({ id: reg, parent: org, type: 'region' });
        groupsToAdd.push({ id: site, parent: reg, type: 'site' });

        addedGroups.forEach((grp) => {
            groupsToAdd.push({ id: grp.group.id, parent: grp.group.parent, type: grp.group.type });
        });
        // remove groups from all devices
        let groupsToRemove = [];
        removeGroups.forEach((grp) => {
            groupsToRemove.push({ id: grp.group.id, parent: grp.group.parent, type: grp.group.type });
        });

        devices.forEach((device) => {
            groupsToAdd.forEach(group => {
                device.groups[`${group.parent}#${group.id}`] = JSON.stringify({type: group.type});
            });

            groupsToRemove.forEach(group => {
                delete device.groups[`${group.parent}#${group.id}`];
            });

            serverPromise.push(API.editDevice(token,
                org,
                encodeURIComponent(device.device.reference),
                JSON.stringify({
                    device: device.device,
                    groups: device.groups,
                    location: device.location,
                    sensor: device.sensor
                })));
        });

        Promise.all(serverPromise).then(() => {

            // send command to map
            this.TagDevicesJSCommands.update('hide_block_ui');
            this.TagDevicesJSCommands.update('reload');

            // update react components
            this.setState({ isSaving: false });
            this.setState({ displayLevelItems: [] });
            this.closeDrawer();

        });

    }

    cancelFormClicked = () => {
        this.closeDrawer();
        this.setState({ displayLevelItems: [] });
        this.TagDevicesJSCommands.update('hide_selection');
    }

    handleBuildingChange = (event, index, buildingGroup) => {
        this.state.menuItems.levelItems.forEach(levelItem => {
            if (levelItem.key &&
              (levelItem.key.substr(0, levelItem.key.lastIndexOf('/')) === buildingGroup.path ||
              // This handles legacy building levels (which have buildingId#levelId as their id
              levelItem.key.substr(0, levelItem.key.lastIndexOf('#')) === buildingGroup.path)) {
                this.state.displayLevelItems.push(levelItem);
            }
        });
        this.handleChange(event, index, buildingGroup);
    }

    handleChange = (event, index, group) => {
        this.createChip(group.key);
    };

    handleRequestDelete = (groupId) => {
        const { selectedGroups, removeGroups } = this.state;

        for (let index = selectedGroups.length - 1; index >= 0; index--) {
            const element = selectedGroups[index];
            if (element.group.id === groupId) {
                selectedGroups.splice(index, 1);
                removeGroups.push(element);
                if (element.group.type === 'building') {
                    this.setState({ displayLevelItems: this.state.displayLevelItems.filter(levelItem => !(levelItem.key.substr(0, levelItem.key.lastIndexOf('#')) === element.group.key)) });
                }
            }
        }

        this.setState({ selectedGroups });
    }

    renderChip = (group) => {
        const key = group.group.id;
        const label = group.group.name;
        return (
            <Chip
                key={key}
                onRequestDelete={() => this.handleRequestDelete(key)}
                style={this.styles.chip}
            >
                {label}
            </Chip>
        );
    }

    render() {

        const { USER } = this.props;
        const site = USER.selectedSite;

        if (!site) return (<MaterialSpinner text="Loading Site..." x="14" />);

        const styles = {
            mapeditor: {
                minWidth: 500,
                minHeight: 600,
                width: '100%',
                height: '100%',
                position: 'absolute',
                right: 0,
                top: 0
            },
            paper: {
                padding: 15,
                marginBottom: 15,
                marginTop: 10
            },
            tablewrapper: {
                marginTop: 10
            },
            horizontalWrapper: {
                display: 'flex',
                flexWrap: 'wrap'
            },
        };

        const {sectorItems, lotItems, timeLimitItems, buildingItems} = this.state.menuItems || {};

        return (

            <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme)}  >
                <div style={styles.mapeditor}>
                    <div id={this.divID} style={styles.mapeditor} >

                    </div>


                    <Drawer width={500} openSecondary={true} open={this.state.showDrawer} >

                        <Paper zDepth={1} style={styles.paper} >
                            <h4>
                                Select Group To Add
                            </h4>
                            <div style={styles.horizontalWrapper}>
                                <SelectField maxHeight={300}
                                    hintText='Sector'
                                    onChange={this.handleChange}
                                    style={{ width: 150, borderColor: '#ffccff' }}
                                >
                                    {sectorItems}
                                </SelectField>
                                <SelectField maxHeight={300}
                                    hintText='Lot'
                                    onChange={this.handleChange}
                                    style={{ width: 150, borderColor: '#ffccff' }}
                                >
                                    {lotItems}
                                </SelectField>
                                <SelectField maxHeight={300}
                                    hintText='Bay Type'
                                    onChange={this.handleChange}
                                    style={{ width: 150, borderColor: '#ffccff' }}
                                >
                                    {timeLimitItems}
                                </SelectField>
                                <SelectField maxHeight={300}
                                    hintText='Building'
                                    onChange={this.handleBuildingChange}
                                    style={{ width: 150, borderColor: '#ffccff' }}
                                >
                                    {buildingItems}
                                </SelectField>
                                {(this.state.displayLevelItems.length > 0) ?
                                    <SelectField maxHeight={300}
                                        hintText='Building Level'
                                        onChange={this.handleChange}
                                        style={{ width: 150, borderColor: '#ffccff' }}
                                    >
                                        {this.state.displayLevelItems}
                                    </SelectField>
                                    :''}
                            </div>
                            <hr />

                            <h4>
                                Selected
                            </h4>

                            <div style={{ height: 200, overflow: 'auto' }}>
                                <div style={{ height: 200, overflowY: 'scroll', position: 'relative' }}>
                                    {this.state.selectedGroups.map(this.renderChip, this)}
                                </div>
                            </div>

                            <hr />


                            {this.state.isSaving ? <CircularProgress /> :
                                <div>
                                    <RaisedButton label="Save" primary={true} onClick={this.saveFromClicked} />
                                    <RaisedButton label="Discard" onClick={this.cancelFormClicked}  style={{
                                        marginLeft: 20
                                    }}  />
                                </div>
                            }


                            {/* {this.state.showStreeView ?
                                <div>
                                    <hr />
                                    <StreetView style={{ height: 200 }} PanoramaOptions={this.state.PanoramaOptions}/>
                                </div>


                                : null} */}

                        </Paper>
                        {
                            this.state.devices &&
                            <h3 style={{padding: 10, marginTop: 10}}>{this.state.devices.length + ' Devices Selected'}</h3>
                        }
                        {
                            this.state.devices &&
                            this.state.devices
                                .sort((a, b) => a.device.name.localeCompare(b.device.name))
                                .map(device => {
                                    return (
                                        <Card key={device.device.reference}>
                                            <CardHeader title={device.device.name} actAsExpander={true} showExpandableButton={true}/>
                                            <CardText expandable={true}>
                                                {this.renderCardText(device)}
                                            </CardText>
                                        </Card>
                                    );
                                })

                        }

                    </Drawer>

                    <Snackbar
                        open={this.state.snakbaropen}
                        message={this.state.snak}
                        autoHideDuration={5000}

                    />


                </div>
            </MuiThemeProvider>
        );
    }

    renderCardText = (device) => {
        const itemStyle = {
            lineHeight: '25px'
        };

        const itemHeadStyle = {
            width: '25%',
            display: 'inline-block',
            fontWeight: 600
        };

        const chipStyle = {
            display: 'inline-block',
            marginRight: '5px'
        };

        return (
            <React.Fragment>
                <div>
                    <p style={itemStyle}><span style={itemHeadStyle}>Description:</span>{device.device.description}</p>
                    <p style={itemStyle}><span style={itemHeadStyle}>Reference:</span>{device.device.reference}</p>
                    <p style={itemStyle}><span style={itemHeadStyle}>Type:</span>{device.device.type === 'sys2' ? 'SENSOR' : 'SMARTSPOT'}</p>
                </div>
                <div>
                    {Object.keys(device.groups)
                        .filter(groupId => device.groups[groupId])
                        .map(groupId => {
                            const group = this.getGroupByRef(groupId);
                            return group && <Chip key={groupId} style={chipStyle}>{group.group.name}</Chip>;
                        })
                    }
                </div>
            </React.Fragment>
        );
    }


}
