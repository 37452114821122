import React from 'react';
import Select from 'react-select';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-select/dist/react-select.css';
import PropTypes from 'prop-types';

import { getSitesFromCache } from '../api/api';
import AppStorage from '../config/AppStorage';
import AppConfig from '../config/AppConfig';

class SiteSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sites: [],
            selected: null,
            loading: false,
            disabled: true,
        };

        this.selectedOrg = '';
        this.selectedRegion = '';

        this.onsiteChange = this.onsiteChange.bind(this);
    }

    onsiteChange(val) {
        let USER = this.props.USER;
        let value = { selected: !val ? null : val.value };
        // save to session storage
        let reloadPage = this.state.selected === null ? false : true;

        AppStorage.setSelectedSite(USER.selectedOrg, USER.selectedRegion, value.selected);


        this.setState(() => value);

        if (value.selected === null) return;

        if (!USER) return;

        if (value.selected !== 'empty') {
            USER.selectedSite = val.value;
            // tell parent
            this.props.onsiteChange(USER.selectedSite);
        }
        if(reloadPage){
            AppStorage.clearUserSites();
            window.location.href = window.location.href.replace('#','');
        }
    }

    componentDidMount() {
    // get last used site and use as default selection when region selected event triggers
    }

    loadSites(selectedOrg, selectedRegion) {
    // get last used site from local or session state

        let sites = [];

        this.setState(() => {
            return { loading: true };
        });

        return getSitesFromCache(selectedRegion).then(sitesResult => {
            AppConfig.setSites(sitesResult);

            sitesResult.sort((a, b) => {
                return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
            });

            sitesResult.forEach(site => {
                const { siteId, name } = site;

                sites.push({
                    value: siteId,
                    label: name,
                    clearableValue: false,
                });
            });

            const sessionStSite = AppStorage.getSelectedSite();
            let selected = null;

            // empty result
            if (sites.length === 0) {
                sites.push({
                    value: 'empty',
                    label: 'No Sites',
                    clearableValue: false,
                });
            } else {
                selected = sites.find(function (site) {
                    return sessionStSite.site === site.value;
                });
            }

            this.setState(function () {
                return {
                    sites: sites,
                    loading: false,
                    disabled: sites.length <= 1,
                };
            });

            if (this.state.selected === null) {
                selected = selected || sites[0];
                this.onsiteChange(selected);
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        let hasChanged = this.selectedOrg !== nextProps.USER.selectedOrg | this.selectedRegion !== nextProps.USER.selectedRegion;
        if (hasChanged && nextProps.USER.selectedOrg && nextProps.USER.selectedRegion) {
            this.loadSites(nextProps.USER.selectedOrg, nextProps.USER.selectedRegion);
        }

        if (!nextProps.USER.selectedOrg || !nextProps.USER.selectedRegion) {
            this.setState(() => {
                return { selected: null, sites: [] };
            });
        }

        this.selectedOrg = nextProps.USER.selectedOrg;
        this.selectedRegion = nextProps.USER.selectedRegion;
    }

    render() {
        return (
            <div>
                <Select
                    name="site-selector"
                    disabled={this.state.disabled}
                    options={this.state.sites}
                    isLoading={this.state.loading}
                    value={this.state.selected}
                    clearable={false}
                    placeholder={'Sites...'}
                    onChange={this.onsiteChange} />
            </div>
        );
    }
}

export default SiteSelector;


SiteSelector.defaultProps = {
    siteChanged: function () { }
};

SiteSelector.propTypes = {
    siteChanged: PropTypes.func.isRequired,
};
