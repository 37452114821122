import { baseURL, API_KEY } from '../config/settings';

function createFetchRequest(url, JWT, method, json) {
    let param = {
        method: method,
        // mode: 'cors',
        redirect: 'follow',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JWT,
            'Api-Key': API_KEY,
        })
    };
    if (json) {
        param.body = json;
    }
    var request = new Request(baseURL + url, param);
    return request;
}

export const fetchRequest = (url, JWT, method, json) => {
    let param = {
        method: method,
        // mode: 'cors',
        redirect: 'follow',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JWT,
            'Api-Key': API_KEY,
        })
    };
    if (json) {
        param.body = json;
    }
    var request = new Request(url, param);
    return request;
};

function json(response) {
    return response.json();
}

function status(response) {

    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error(response.statusText));
    }
}


export { createFetchRequest, json, status };