import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import Snackbar from 'material-ui/Snackbar';
import FontIcon from 'material-ui/FontIcon';

import React from 'react';

import { Link } from 'react-router-dom';

import Routes from './components/routes/Routes';

import { red900 } from 'material-ui/styles/colors';

import TopRightNavBar from './topNaviagtion/TopRightNavBar';

import * as API from './api/api';

const DRAWER_CLASS_FOR_MENU_HIDE = 'smart-drawer';

class ProtectedContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false,
            userExternalLinks: [],
            appName: 'Place Devices',
            snackbarToggle: false,
            snackbarAutoHideDuration: 5000,
            snackbarMessage: '',
            snackbarOpen: false,
        };
    }

    componentDidMount() {
        const { USER } = this.props;
        const self = this;
        window.updateDashBoardVersion = (snackbarMessage) => {
            self.setState({ snackbarMessage, snackbarOpen: true });
        };

        if (!USER.selectedSite) {
            return;
        }
        this.loadReports();


    }

    componentWillReceiveProps(nextProps) {
        const { USER } = nextProps;

        if (!USER || !USER.selectedSite) {
            return;
        }
        this.loadReports();
    }

    openCloseDrawer = () => {
        this.setState(state => ({ ...state, drawerOpen: !state.drawerOpen }));
    };

    handleMenuItemClick = item => {
        this.setState(state => ({ ...state, drawerOpen: false, appName: item.name }));
    };

    handleDrawerRequestChange = drawerOpen => {
        this.setState(state => ({ ...state, drawerOpen }));
    };

    loadReports = () => {
        const { USER } = this.props;
        if (!USER) return;

        API.getUserExternalLinkReports(USER).then(reports => {
            this.setState({ userExternalLinks: reports });
        });
    };

    getExternalLinks = userExternalLinks => {
        return userExternalLinks.map(report => {
            return {
                name: report.report.title,
                link: encodeURI(report.report.url),
                icon: 'launch',
                type: 'exlink'
            };
        });
    };

    handleRequestClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };

    handleActionClick = () => {
        window.location.href = window.location.href.replace('#', '');
    };

    render() {
        const navLinks = [
            {
                name: 'Place Devices',
                link: '/devices/map',
                icon: 'pin_drop'
            },
            {
                name: 'Group Devices',
                link: '/devices/group',
                icon: 'storage'
            }
        ];

        const exlinks = this.getExternalLinks(this.state.userExternalLinks);
        if (exlinks.length > 0) {
            exlinks.forEach(link => {
                navLinks.push(link);
            });
        }

        const Settings = {
            name: 'Settings',
            link: '',
            icon: 'storage',
            prop: {
                bottom: true
            },
            links: [
                {
                    name: 'Add External Tool',
                    link: '/settings/add-external-tool',
                    icon: 'view_quilt'
                }
            ]
        };

        navLinks.push(Settings);

        return (
            <MuiThemeProvider>
                <div
                    style={{
                        position: 'relative',
                        height: '100%'
                    }}
                >
                    <AppBar
                        style={{ backgroundColor: red900 }}
                        title={this.state.appName}
                        onLeftIconButtonClick={this.openCloseDrawer}
                        iconElementRight={<TopRightNavBar {...this.props} />}
                    />
                    <Drawer
                        open={this.state.drawerOpen}
                        docked={false}
                        onRequestChange={drawerOpen => this.handleDrawerRequestChange(drawerOpen)}
                        className={DRAWER_CLASS_FOR_MENU_HIDE}
                    >
                        {navLinks.map((item, index) => {
                            if (!item) return <div />;
                            const prop = item.prop || {};
                            const key = item.name + index;

                            if (prop.bottom) {
                                return (
                                    <div key={key} style={{ bottom: '0px', position: 'absolute', width: '100%' }}>
                                        <NavItem item={item} key={key} handleMenuItemClick={this.handleMenuItemClick} />
                                    </div>
                                );
                            }

                            return <NavItem item={item} key={key} handleMenuItemClick={this.handleMenuItemClick} />;
                        })}
                    </Drawer>

                    <div style={{ position: 'relative', height: 'calc(100% - 67px)' }}>
                        <Routes {...this.props} exlinks={exlinks} />
                        <Snackbar
                            open={this.state.snackbarOpen}
                            message={this.state.snackbarMessage}
                            action="Refresh"
                            autoHideDuration={this.state.snackbarAutoHideDuration}
                            onActionClick={this.handleActionClick}
                            onRequestClose={this.handleRequestClose}
                        />
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default ProtectedContent;

class NavItem extends React.Component {
    handleMenuItemClick = item => {
        this.props.handleMenuItemClick(item);
    };

    render() {
        const { item } = this.props;
        const iconEmpty = !item.icon || item.icon === '';
        const urlEmpty = !item.link || item.link === '';
        const links = item.links || [];

        return (
            <MenuItem
                containerElement={
                    urlEmpty === false ? (
                        item.type === 'exlink' ? (
                            <a href={item.link} target="_blank" >&nbsp;</a>
                        ) : (
                            <Link to={item.link} />
                        )
                    ) : (
                        <div />
                    )
                }
                primaryText={item.name}
                leftIcon={iconEmpty === false ? <FontIcon className="material-icons">{item.icon}</FontIcon> : <div />}
                onClick={() => {
                    return links.length === 0 ? this.handleMenuItemClick(item) : null;
                }}
                menuItems={
                    links.length !== 0
                        ? links.map((item, key) => {
                            key = item.name + key;
                            return <NavItem item={item} key={key} handleMenuItemClick={this.handleMenuItemClick} />;
                        })
                        : null
                }
            />
        );
    }
}

NavItem.defaultProps = {
    updateDrawerState: function () { }
};
