
import React from 'react';

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import Chip from 'material-ui/Chip';
import Snackbar from 'material-ui/Snackbar';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import AppConfig from '../../config/AppConfig';
import * as API from '../../api/api';
import 'bootstrap-grid-only/bootstrap.css';

const firebase = require('firebase');
const FIELD_REQUIRED = 'This field is required';
const FIELD_REQUIRED_HTTP = 'Url must begin with http or https  ';


export default class ExternalLink extends React.Component {

    constructor(props) {
        super(props);
        this.mounted = false;

        this.state = {
            value: AppConfig.REPORT_CAT_SMART_DATA,
            isSaving: false,
            userExternalLinks: [],
            snakbaropen: false,
            dialog_open: false,
            snak: '',
            reportName: '',
            reportUrl: '',
        };

        this.styles = {
            chip: {
                margin: 4,
            }
        };

    }

    componentDidMount() {
        this.mounted = true;
        const { USER } = this.props;

        if (!USER.selectedSite) {
            return;
        }


        this.selectedSite = USER.selectedSite;
        this.loadReports();
    }

    componentWillReceiveProps(nextprops) {
        const { USER } = nextprops;
        if (!USER.selectedSite) {
            return;
        }
        if (this.selectedSite !== USER.selectedSite) {
            this.loadReports();
        }
        this.selectedSite = USER.selectedSite;
    }

    loadReports = () => {
        const { USER } = this.props;

        if (!USER.selectedSite) {
            return;
        }
        this.showSnakBar('Loading....');

        this.updateState({
            reportName: '',
            reportUrl: '',
            ref:null,
            isSaving: false,
        });

        API.getUserExternalLinkReports(USER).then(reports => {
            this.updateState({ userExternalLinks: reports });
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    updateState = (obj, callback = function () { }) => {
        if (this.mounted === false) return;
        this.setState({ ...obj }, callback);
    }

    handleChange = (event, index, value) => this.updateState({ value });

    showSnakBar = (message) => {
        this.updateState({ snakbaropen: true, snak: message });
    }

    changeSelectedItem = (evt, field) => {
        let obj = {};
        obj[field] = evt.target.value;
        this.updateState(obj);
    }

    saveFromClicked = () => {
        const { USER } = this.props;
        const { reportName, reportUrl, ref } = this.state;

        if (!USER.selectedSite) {
            return;
        }

        if (reportName.trim() === '') {
            this.updateState({ errorTextReportName: FIELD_REQUIRED });
            return;
        } else {
            this.updateState({ errorTextReportName: '' });
        }

        if (reportUrl.trim() === '') {
            this.updateState({ errorTextReportUrl: FIELD_REQUIRED });
            return;
        }

        let url = reportUrl;

        if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
            this.updateState({ errorTextReportUrl: FIELD_REQUIRED_HTTP });
            return;
        }

        this.updateState({ isSaving: true });

        this.resetErrorValidationFromFields();

        let report = {
            url,
            title: reportName.trim()
        };

        API.saveUserExternalLink(firebase, USER, report, ref).then((success) => {
            if (success) {
                this.loadReports();
            }
            this.updateState({ isSaving: false });
        });

    }
    resetErrorValidationFromFields = () => {
        this.updateState({
            errorTextReportName: '',
            errorTextReportUrl: '',
        });

    }

    handleDialogClose = (confim) => {
        const { USER } = this.props;
        this.updateState({ dialog_open: false });
        if (confim) {
            API.deleteSiteReports(USER, this.reportToDelete).then(() => {
                this.loadReports();
            });
        } else {
            this.reportToDelete = null;
        }
    };

    handleRequestDelete = (ref) => {
        this.reportToDelete = ref;
        this.updateState({ dialog_open: true });
    }
    setReport = (report) => {
        this.updateState({ ...report, isSaving: false });
    }
    editReport = (data) => {
        const { title, url, ref } = data.report;
        this.updateState({ reportName: title, reportUrl: url, ref: ref });
    }

    addNewReport = () => {
        this.updateState({ reportName: '', reportUrl: '', ref: null, isSaving: false });
    }

    renderChip(data) {
        const { ref } = this.state;
        let color = '#1E88E5';
        if (ref === data.ref) {
            color = '#004D40';
        }
        return (
            <Chip
                backgroundColor={color}
                key={data.ref}
                onRequestDelete={() => this.handleRequestDelete(data.ref)}
                style={this.styles.chip}
                onClick={() => {
                    this.editReport(data);
                }}
            >
                {data.report.title}
            </Chip>
        );
    }
    handleSnackBarRequestClose = () => {
        this.updateState({
            snakbaropen: false,
        });
    };
    render() {
        const { userExternalLinks, errorTextReportName, reportName, errorTextReportUrl, reportUrl, isSaving } = this.state;

        const styles = {
            paper: {
                padding: 15,
                marginBottom: 15,
                marginTop: 10,
                minHeight: 400
            }

        };

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.handleDialogClose.bind(null, false)}
            />,
            <FlatButton
                label="Confirm"
                primary={true}
                keyboardFocused={true}
                onClick={this.handleDialogClose.bind(null, true)}
            />,
        ];

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme)}  >

                <div id={this.props.id} style={{ padding: '10px 40px 0' }} >
                    <div className="page-title">
                        <div className="title_left">
                            <h3>External Links</h3>
                        </div>
                        <FloatingActionButton
                            style={{ float: 'right' }}
                            mini={true}
                            onClick={this.addNewReport}
                        >
                            <ContentAdd />
                        </FloatingActionButton>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg4" style={{ marginRight: 15 }}>
                        <Paper zDepth={1} style={styles.paper} >

                            <h3>Available Links</h3>
                            <hr />

                            {userExternalLinks.map(this.renderChip, this)}


                        </Paper>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg4" >
                        <Paper zDepth={1} style={styles.paper} >

                            <TextField
                                hintText=""
                                floatingLabelText="Link Name"

                                errorText={errorTextReportName}
                                onChange={
                                    (e) => this.changeSelectedItem(e, 'reportName')
                                }
                                value={reportName}
                            />
                            <br />

                            <TextField
                                hintText=""
                                floatingLabelText="Url"

                                errorText={errorTextReportUrl}
                                onChange={
                                    (e) => this.changeSelectedItem(e, 'reportUrl')
                                }
                                value={reportUrl}
                            />

                            {isSaving === false ?
                                <div>
                                    <RaisedButton label="Save" primary={true} onClick={this.saveFromClicked} />
                                </div>
                                : <CircularProgress />}
                        </Paper>
                    </div>

                    <Snackbar
                        open={this.state.snakbaropen}
                        message={this.state.snak}
                        autoHideDuration={5000}
                        onRequestClose={this.handleSnackBarRequestClose}
                    />

                    <Dialog
                        title="Confirm"
                        actions={actions}
                        modal={false}
                        open={this.state.dialog_open}
                        onRequestClose={this.handleDialogClose}
                    >
                        Confirm Delete?
                    </Dialog>


                </div>
            </MuiThemeProvider>
        );
    }



}
