import {config} from './firebaseAuthConfig';
class AppConfig {
  static NAME = 'Site Planner';
  static VERSION = '1.0';
  static USER = null;

  static setTitle(title) {
    document.title = (!title) ? AppConfig.NAME : `${AppConfig.NAME} | ${title}`;
  }
  static _REGIONS;
  static _ORGS;
  static _SITES;
  static _USER_SITES;
  static DB_PATH_USER_EXTERNAL_LINKS = '/site_planner/user-external-links'

  static setRegions(regions) {
    AppConfig._REGIONS = regions;
  }
  static setSites(sites) {
    AppConfig._SITES = sites;
  }
  static setOrgs(orgs) {
    AppConfig._ORGS = orgs;
  }
  static setFirebaseApp(App){
    AppConfig.App = App;
  }
  static setUserSites(r){
    AppConfig._USER_SITES = r;
  }
  static getUserSites(){
    return AppConfig._USER_SITES;
  }

  static getOrgs() {
    return AppConfig._ORGS;
  }
  static getRegions() {
    return AppConfig._REGIONS;
  }
  static getSites() {
    return AppConfig._SITES;
  }

  static getOrgLocation(org) {
    let ORGS = AppConfig.getOrgs();
    let location;
    if (ORGS) {
      ORGS.forEach((item) => {
        if (item.orgId === org) {
          location = { lat: Number(item.lat), lng: Number(item.long) };
        }
      });
    }
    return location;
  }

  static getSiteLocation(site) {
    let SITES = AppConfig.getSites();
    let location;
    if (SITES) {
      SITES.forEach((item) => {
        if (item.siteId === site) {
          location = { lat: Number(item.lat), lng: Number(item.long) };
        }
      });
    }
    return location;
  }

  static loadScript(scriptUrl, callback) {
    let self = this;
    if (!scriptUrl) {
      callback();
      return;
    }

    var scripts = document.getElementsByTagName('script');
    var scriptExists = false;

    let dateTime = new Date().getTime() / 1000;
    if (scriptUrl.toString().includes('?')) {
      scriptUrl = scriptUrl + '&t=' + dateTime;
    } else {
      scriptUrl = scriptUrl + '?t=' + dateTime;
    }

    for (var i = scripts.length; i--;) {
      if (scripts[i].src === scriptUrl) {
        scriptExists = true;
      }
    }
    if (scriptExists === true) {
      callback();
    } else {
      var head = document.getElementsByTagName('head')[0];
      var jsToAdd = document.createElement('script');
      jsToAdd.type = 'text/javascript';
      jsToAdd.src = scriptUrl;
      jsToAdd.onreadystatechange = callback;
      jsToAdd.context = self;
      jsToAdd.onload = function () {
        callback();
      };
      head.appendChild(jsToAdd);
    }
  }

  static getUserExternalLinkPath(USER) {
    const dashPath = AppConfig.DB_PATH_USER_EXTERNAL_LINKS;
    const orgregsiteRef = AppConfig.getSiteRef(USER);
    let id = USER.uid;
    return `${dashPath}/${id}/${orgregsiteRef}/`;
  }

  static getSiteRef(USER) {
    const org = USER.selectedOrg;
    const reg = USER.selectedRegion;
    const site = USER.selectedSite;
    return '__' + org + '-' + reg + '-' + site;

  }

  static getDbCon(){
    return AppConfig.App.database(`https://${config.projectId}.firebaseio.com/`);
  }
}

export default AppConfig;