
// Initialize Firebase
var config = {
  apiKey: "AIzaSyAVzarfMi8xfaVRQZnQN6Qg1SvlnkD3XIs",
  authDomain: "console.smartcloud.smartsys.io",
  databaseURL: "https://sc-neptune-production.firebaseio.com",
  projectId: "sc-neptune-production",
  storageBucket: "sc-neptune-production.appspot.com",
  messagingSenderId: "537905849478"
};


export {config}