export default class Helpers {
    static getUserInitials(name) {
        let r = '';
        try {
            name = name.replace(/^(.+)@(.+)$/g, '$1');
            let names = name.split('.');
            if (names.length < 2) {
                names = name.split(' ');
            }

            r = names[0].charAt(0).toUpperCase() + (names.length >= 2 ?
                names[1].charAt(0).toUpperCase() : '');
        } catch (e) {
            console.log(e);
        }
        return r;

    }

    static getURLParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    static is404(url) {
        var myInit = {
            method: 'get',
            redirect: 'follow',
            mode: 'no-cors',
        };

        var myRequest = new Request(url, myInit);
        return fetch(myRequest).then(function (response) {
            // return true;
            return Promise.resolve(response.status >= 400);
        }).catch(() => {
            return Promise.resolve(true);
        });
    }

}
