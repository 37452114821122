
const LOCAL_SELECTED_ORG = 'SITESURVEY-ORG';
const LOCAL_SELECTED_REG = 'SITESURVEY-REG';
const LOCAL_SELECTED_SITE = 'SITESURVEY-SITE';
const LOCAL_DASHBOARD = 'SITESURVEY-DASH_VERSION';
const LOCAL_USER_SITES = 'LOCAL_USER_SITES';

class AppStorage {

  static setStorageObject(store, name, value) {
    store.removeItem(name);
    if (typeof value !== 'undefined' && value !== null) {
      var item = (typeof value === 'object') ? JSON.stringify(value) : value;
      // save it
      store.setItem(name, item);
    }
  }

  static clearAllStorage() {
    window.localStorage.clear();
    window.sessionStorage.clear();

  }

  static getDashboardVersion() {
    return AppStorage.getItemFromStorage(true, LOCAL_DASHBOARD);
  }

  static saveDashboardVersion(version) {
    AppStorage.setStorageObject(window.sessionStorage, LOCAL_DASHBOARD, version);
    AppStorage.setStorageObject(window.localStorage, LOCAL_DASHBOARD, version);
  }

  static getItemFromStorage(useLocalStorage, name) {
    if (useLocalStorage) {
      return window.localStorage.getItem(name);
    }
    return window.sessionStorage.getItem(name);
  }

  static setSelectedSite(org, reg, site) {
    AppStorage.setStorageObject(window.sessionStorage, LOCAL_SELECTED_ORG, org);
    AppStorage.setStorageObject(window.sessionStorage, LOCAL_SELECTED_REG, reg);
    AppStorage.setStorageObject(window.sessionStorage, LOCAL_SELECTED_SITE, site);

    AppStorage.setStorageObject(window.localStorage, LOCAL_SELECTED_ORG, org);
    AppStorage.setStorageObject(window.localStorage, LOCAL_SELECTED_REG, reg);
    AppStorage.setStorageObject(window.localStorage, LOCAL_SELECTED_SITE, site);


  }
  static getSelectedSite() {
    let useLocalStorage = false;
    // check for session storage
    let org = AppStorage.getItemFromStorage(useLocalStorage, LOCAL_SELECTED_ORG);
    if (!org) {
      useLocalStorage = true;
    }

    let stored = {
      org: AppStorage.getItemFromStorage(useLocalStorage, LOCAL_SELECTED_ORG),
      reg: AppStorage.getItemFromStorage(useLocalStorage, LOCAL_SELECTED_REG),
      site: AppStorage.getItemFromStorage(useLocalStorage, LOCAL_SELECTED_SITE)
    };
    return stored;
  }

  static setUserSites(user, sites) {
    let expires = new Date().getTime() + 30 * 60000;
    AppStorage.setStorageObject(window.localStorage, LOCAL_USER_SITES, JSON.stringify({ user, sites, expires }));
  }

  static getUserSites(user) {
    let sites = AppStorage.getItemFromStorage(true, LOCAL_USER_SITES);
    if (!sites) return;
    let sitesJson = JSON.parse(sites);
    if (sitesJson.user !== user || sitesJson.expires && sitesJson.expires < new Date().getTime()) {
      AppStorage.clearUserSites();
      return null;
    }
    return sitesJson.sites;
  }

  static clearUserSites(){
    console.log('cleared user site cache');
    window.localStorage.removeItem(LOCAL_USER_SITES);
  }
}

export default AppStorage;