
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PlaceDevices from '../PlaceDevices';
import GroupDevices from '../GroupDevices';
import ExternalLink from '../externalLink/ExternalLink';

export default class Routes extends React.Component {


    render() {
        const { token, USER } = this.props;

        const propsToSend = { token, USER, id: '__innercontent' };

        return (

            <Switch>

                <Route exact path="/devices/map" render={() => <PlaceDevices  {...propsToSend} />} />
                <Route exact path="/devices/group" render={() => <GroupDevices  {...propsToSend} />} />
                <Route exact path="/" render={() => <PlaceDevices  {...propsToSend} />} />
                <Route exact path="/index.html" render={() => <PlaceDevices  {...propsToSend} />} />
                <Route exact path="/settings/add-external-tool" render={() => <ExternalLink  {...propsToSend} />} />
            </Switch>


        );

    }
}
